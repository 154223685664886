import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { List } from "react-virtualized";
import { Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import Moment from "moment";
import TaskCard from "./task_card";
import SortIcon from "../icons/sort_icon";

const getRowRender = (
  projects,
  modalHandler,
  leadOptions,
  userOptions,
  tagOptions,
  currentUser,
  stateCheckHandler,
  projectHandler,
  loadingCard,
  droppedActive,
  taskboardUserOptions,
  projectsHandler
) => {
  const rowRender = ({ index, style }) => {
    const project = projects[index];

    // We are rendering an extra item for the placeholder
    // Do this we increased our data set size to include one 'fake' item
    if (!project) {
      return null;
    }
    // Faking some nice spacing around the items
    const patchedStyle = {
      ...style,
      top: style.top,
      width: "100%",
      height: style.height - 5,
    };

    return (
      <Draggable
        draggableId={project.id.toString()}
        index={index}
        something={project}
        style={null}
        isDragDisabled={project.id === loadingCard || droppedActive}
        key={project.id}
      >
        {(provided, snapshot) => (
          <TaskCard
            loading={project.id === loadingCard}
            style={patchedStyle}
            project={project}
            currentUser={currentUser}
            leadOptions={leadOptions}
            tagOptions={tagOptions}
            userOptions={userOptions}
            modalHandler={modalHandler}
            projectHandler={projectHandler}
            projectsHandler={projectsHandler}
            stateCheckHandler={stateCheckHandler}
            provided={provided}
            taskboardUserOptions={taskboardUserOptions}
            isDragging={snapshot.isDragging}
            columnKey={project.project_status?.aasm_state}
            key={Math.random()}
            refProp={provided.innerRef}
          />
        )}
      </Draggable>
    );
  };

  return rowRender;
};

function TaskboardColumn(props) {
  const {
    columnKey,
    projects,
    totalCount,
    loadingCard,
    modalHandler,
    leadOptions,
    tagOptions,
    userOptions,
    currentUser,
    stateCheckHandler,
    projectHandler,
    activeDragColumn,
    columnIndex,
    droppedActive,
    taskboardUserOptions,
    projectsHandler,
    searchInput,
    assignedMe,
    loadMoreProjects,
    isLoading,
  } = props;
  const [sortOpen, setSortOpen] = useState(false);
  const [sortCreated, setSortCreated] = useState(2);
  const [sortUpdated, setSortUpdated] = useState(0);
  const [offset, setOffset] = useState(0);

  const getHeight = () => window.innerHeight - 325;
  function useCurrentHeight() {
    // save current window height in the state object
    const [height, setHeight] = useState(getHeight());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
      // timeoutId for debounce mechanism
      let timeoutId = null;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
        // change height from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setHeight(getHeight()), 150);
      };
      // set resize listener
      window.addEventListener("resize", resizeListener);
      document.title = "Syncit - Taskboard";

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeListener);
      };
    }, []);

    return height;
  }

  function getBackgroundColor() {
    return "#D7D7D7";
  }

  const wordMap = {
    leading: "New Project",
    est_requesting: "Estimate Request",
    est_sending: "Estimate Sent",
    project_confirming: "Project Confirmed",
    testing: "Test In Progress",
    completing: "Project Complete",
    readying: "Ready for Invoice",
    invoicing: "Invoiced",
  };

  const heightMap = {
    leading: 119,
    est_requesting: 118,
    est_sending: 127,
    project_confirming: 119,
    testing: 118,
    completing: 118,
    readying: 118,
    invoicing: 118,
  };

  function columnMouseEnter(e) {
    e.currentTarget.children[0].style.overflow = "auto auto";
    if (
      e.currentTarget.children[0] &&
      e.currentTarget.children[0].scrollHeight >
        e.currentTarget.children[0].clientHeight
    ) {
      e.currentTarget.children[1].style.width = "310px";
      e.currentTarget.children[2].style.width = "310px";
    }
  }

  function columnMouseLeave(e) {
    e.currentTarget.children[0].style.overflow = "hidden hidden";
    e.currentTarget.children[1].style.width = "320px";
    e.currentTarget.children[2].style.width = "320px";
  }

  function handleCreated() {
    if (sortCreated === 0) {
      setSortCreated(1);
      setSortUpdated(0);
    } else if (sortCreated === 1) {
      setSortCreated(2);
      setSortUpdated(0);
    } else {
      setSortCreated(1);
    }
  }

  function handleUpdated() {
    if (sortUpdated === 0) {
      setSortUpdated(1);
      setSortCreated(0);
    } else if (sortUpdated === 1) {
      setSortUpdated(2);
      setSortCreated(0);
    } else {
      setSortUpdated(1);
    }
  }

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (sortOpen && e.target && typeof e.target.className === "string") {
        if (
          e.target.id === "sort-box" ||
          e.target.className.includes("btn-flat") ||
          e.target.id === "sort-button"
        ) {
          // don't close
        } else {
          window.removeEventListener("click", handleOutsideClick);
          setSortOpen(false);
        }
      } else {
        window.removeEventListener("click", handleOutsideClick);
      }
    };

    if (sortOpen) {
      window.addEventListener("click", handleOutsideClick);
    } else {
      window.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [sortOpen]);

  useEffect(() => {
    const columns = $(".ReactVirtualized__Grid.ReactVirtualized__List");
    if (columns) {
      for (let index = 0; index < columns.length; index++) {
        const column = columns[index];
        if (
          column &&
          column.children &&
          column.children[0] &&
          column.children[0].children.length < 5
        ) {
          column.parentElement.children[1].style.opacity = "0";
          column.parentElement.children[2].style.opacity = "0";
        } else if (
          column &&
          column.children &&
          column.children[0] &&
          column.children[0].children.length >= 5
        ) {
          column.parentElement.children[1].style.opacity = "0";
          column.parentElement.children[2].style.opacity = "1";
        }
      }
    }
  }, [projects]);

  function sortClick() {
    setSortOpen(!sortOpen);
  }

  const onScroll = async (object) => {
    if (searchInput || droppedActive) return;
    const elem = document.getElementById(`${columnKey}_column`);
    const scrollMargin = 5;

    if (elem && elem.clientHeight < elem.scrollHeight) {
      if (
        object.clientHeight + object.scrollTop === object.scrollHeight &&
        object.scrollTop !== 0
      ) {
        elem.parentElement.children[1].style.opacity = "1";
        elem.parentElement.children[2].style.opacity = "0";
      } else if (object.scrollHeight > 135) {
        elem.parentElement.children[2].style.opacity = "1";
      }
      if (object.scrollTop === 0 && object.scrollHeight > 135) {
        elem.parentElement.children[1].style.opacity = "0";
        elem.parentElement.children[2].style.opacity = "1";
      }
      if (object.scrollTop !== 0) {
        elem.parentElement.children[1].style.opacity = "1";
      }
    }

    if (
      Math.abs(object.clientHeight + object.scrollTop - object.scrollHeight) <=
        scrollMargin &&
      object.scrollTop !== 0
    ) {
      const newOffset = offset + 20;
      setOffset(newOffset);

      loadMoreProjects(columnKey, newOffset);
    }
  };

  const sortedProjects = useMemo(
    () =>
      sortByDate(
        handleSearchUpdate(searchInput, assignedMe, currentUser.id, projects),
        sortCreated,
        sortUpdated
      ),
    [
      searchInput,
      currentUser.id,
      sortUpdated,
      sortCreated,
      assignedMe,
      projects,
    ]
  );

  const maxHeight = useCurrentHeight();

  function handleSearchUpdate(input, assignedMe, id, projects) {
    if (input) {
      projects = projects.filter((p) =>
        `${p.name ? p.name.toLowerCase() : ""} ${
          p.client_name ? p.client_name.toLowerCase() : ""
        }`.includes(input.toLowerCase())
      );
    }
    if (assignedMe) {
      return projects.filter((p) =>
        [
          ...p.lead_ids,
          ...[p.estimate_owner_id],
          ...[p.lead_owner_id],
        ].includes(id)
      );
    }
    return projects;
  }

  function sortByDate(projects, sortCreated, sortUpdated) {
    let direction = 0;
    let key;
    if (sortCreated != 0) {
      key = "created_at";
      direction = sortUpdated + sortCreated;
    } else if (sortUpdated !== 0) {
      key = "updated_at";
      direction = sortUpdated + sortCreated;
    }
    // 1 = descending 2 = descending
    let sortedArray = projects;
    if (direction >= 1) {
      if (direction === 1) {
        sortedArray = projects.sort((a, b) =>
          Moment(a[key]).diff(Moment(b[key]))
        );
      } else {
        sortedArray = projects.sort((a, b) =>
          Moment(b[key]).diff(Moment(a[key]))
        );
      }
    }
    return sortedArray;
  }

  return (
    <ColumnContainer
      id={columnKey}
      columnIndex={columnIndex}
      activeDragColumn={activeDragColumn}
      scrolling={(projects.length * heightMap[columnKey]).toString()}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        <div className="flex-container flex-al-center">
          <p
            style={{
              fontFamily: "Manrope",
              margin: 0,
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            {wordMap[columnKey]}
          </p>
          <div
            className="tag"
            style={{
              height: "24px",
              width: "75px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "10px",
              backgroundColor: "#519acc",
            }}
          >
            <p
              style={{
                color: "white",
                fontSize: "11px",
                fontFamily: "Open Sans",
              }}
            >
              {totalCount} Cards
            </p>
          </div>
        </div>
        <button
          className={sortOpen ? "open" : ""}
          onClick={sortClick}
          id="sort-button"
        >
          <SortIcon color={sortOpen ? "white" : "#519acc"} />
        </button>
        {sortOpen === true && (
          <div id="sort-box">
            <p
              style={{
                fontFamily: "arial",
                color: "#6e6e6e",
                fontSize: "14px",
                alignSelf: "flex-start",
                margin: "10px 20px",
              }}
            >
              SORT BY
            </p>
            <div
              style={{ padding: "5px" }}
              className="flex-container flex-column flex-al-center"
            >
              <div>
                <button
                  onClick={handleCreated}
                  className="btn-flat flex-container flex-al-center"
                >
                  Created Date
                  {sortCreated === 1 && (
                    <i
                      style={{
                        fontSize: "20px",
                        pointerEvents: "none",
                        marginLeft: "5px",
                      }}
                      className="material-icons base-color"
                    >
                      arrow_downward
                    </i>
                  )}
                  {sortCreated === 2 && (
                    <i
                      style={{
                        fontSize: "20px",
                        pointerEvents: "none",
                        marginLeft: "5px",
                      }}
                      className="material-icons base-color"
                    >
                      arrow_upward
                    </i>
                  )}
                </button>
              </div>
              <div>
                <button
                  onClick={handleUpdated}
                  className="btn-flat flex-container flex-al-center"
                >
                  Last Updated
                  {sortUpdated === 1 && (
                    <i
                      style={{
                        fontSize: "20px",
                        pointerEvents: "none",
                        marginLeft: "5px",
                      }}
                      className="material-icons base-color"
                    >
                      arrow_downward
                    </i>
                  )}
                  {sortUpdated === 2 && (
                    <i
                      style={{
                        fontSize: "20px",
                        pointerEvents: "none",
                        marginLeft: "5px",
                      }}
                      className="material-icons base-color"
                    >
                      arrow_upward
                    </i>
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Droppable
        // isDropDisabled={(columnIndex != activeDragColumn && columnIndex <= activeDragColumn + 1)}
        droppableId={`${columnKey}`}
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => (
          <TaskCard
            loadingCard={sortedProjects[rubric.source.index].id === loadingCard}
            style={provided.draggableProps.style}
            leadOptions={leadOptions}
            userOptions={userOptions}
            tagOptions={tagOptions}
            project={sortedProjects[rubric.source.index]}
            isDragging={snapshot.isDragging}
            provided={provided}
            projectHandler={projectHandler}
            projectsHandler={projectsHandler}
            stateCheckHandler={stateCheckHandler}
            taskboardUserOptions={taskboardUserOptions}
            columnKey={columnKey}
            currentUser={currentUser}
          />
        )}
      >
        {(droppableProvided, snapshot) => {
          const itemCount = snapshot.isUsingPlaceholder
            ? sortedProjects.length + 1
            : sortedProjects.length;
          return (
            <div
              onMouseEnter={columnMouseEnter}
              onMouseLeave={columnMouseLeave}
            >
              <List
                height={maxHeight}
                rowCount={itemCount}
                rowHeight={heightMap[columnKey]}
                width={330}
                onScroll={onScroll}
                id={`${columnKey}_column`}
                overscanRowCount={3}
                ref={(ref) => {
                  // react-virtualized has no way to get the list's ref that I can so
                  // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                  if (ref) {
                    // eslint-disable-next-line react/no-find-dom-node
                    const whatHasMyLifeComeTo = ReactDOM.findDOMNode(ref);
                    if (whatHasMyLifeComeTo instanceof HTMLElement) {
                      droppableProvided.innerRef(whatHasMyLifeComeTo);
                    }
                  }
                }}
                style={{
                  backgroundColor: getBackgroundColor(
                    snapshot.isDraggingOver,
                    Boolean(snapshot.draggingFromThisWith)
                  ),
                  transition: "background-color 0.2s ease",
                }}
                rowRenderer={getRowRender(
                  sortedProjects,
                  modalHandler,
                  leadOptions,
                  userOptions,
                  tagOptions,
                  currentUser,
                  stateCheckHandler,
                  projectHandler,
                  loadingCard,
                  droppedActive,
                  taskboardUserOptions,
                  projectsHandler
                )}
              />
              <div className="top-tb-blur" />
              <div className="bottom-tb-blur" />
              {isLoading && <span class="loader" />}
            </div>
          );
        }}
      </Droppable>
    </ColumnContainer>
  );
}

const ColumnContainer = styled.div`
  padding: 16px;
  background-color: #d7d7d7;
  flex-shrink: 0;
  margin: 0px 4px 5px;
  position: relative;
  display: flex;
  opacity: ${(props) =>
    props.activeDragColumn === -1
      ? 1
      : props.columnIndex <= props.activeDragColumn ||
        props.columnIndex === props.activeDragColumn + 1
      ? "1"
      : ".6"};
  flex-direction: column;
  border-radius: 10px;
`;

export default TaskboardColumn;
