import React from "react";
import PropTypes from "prop-types";
import ExitIcon from "components/icons/exit_icon";
import ClockSolidIcon from "components/icons/clock_solid_icon";
import NewProjectIcon from "components/icons/new_project_icon";
import ms from "./ModalStyled";

/* To add this headline to a new modal, add your modalkey below,
 * add the corresponding TITLE_ICONS and TITLE_READABLE maps to render
 * icon and title, and render the headline with modalKey and closeModal
 * props. */

export const [
  TIME_OFF_REQUEST,
  REPORT_SICK_TIME,
  NEW_LEAD,
  LEAD_TRANSITION,
  REQUESTING_TRANSITION,
  SENDING_TRANSITION,
  COMPLETE_TRANSITION,
  READYING_TRANSITION,
  READYING_BACK_TRANSITION,
] = [
  "time_off_request",
  "report_sick_time",
  "new_lead",
  "lead_transition",
  "requesting_transition",
  "sending_transition",
  "complete_transition",
  "readying_transition",
  "readying_back_transition",
];

const ModalHeadline = (props) => {
  const { modalKey, closeModal, subHeading } = props;
  const TITLE_ICONS = {
    [TIME_OFF_REQUEST]: <ClockSolidIcon height={34} width={34} color="white" />,
    [REPORT_SICK_TIME]: <ClockSolidIcon height={34} width={34} color="white" />,
    [NEW_LEAD]: <NewProjectIcon />,
    [LEAD_TRANSITION]: <NewProjectIcon />,
    [REQUESTING_TRANSITION]: <NewProjectIcon />,
    [SENDING_TRANSITION]: <NewProjectIcon />,
    [COMPLETE_TRANSITION]: <NewProjectIcon />,
    [READYING_TRANSITION]: <NewProjectIcon />,
    [READYING_BACK_TRANSITION]: <NewProjectIcon />,
  };

  const TITLE_READABLE = {
    [TIME_OFF_REQUEST]: "Request Vacation Time",
    [REPORT_SICK_TIME]: "Submit Sick Time Off",
    [NEW_LEAD]: "Add a Project",
    [LEAD_TRANSITION]: "Move to Estimate Request",
    [REQUESTING_TRANSITION]: "Move to Estimate Sent",
    [SENDING_TRANSITION]: "Move to Project Confirmed",
    [COMPLETE_TRANSITION]: "Move to Ready for Invoice",
    [READYING_TRANSITION]: "Confirm Invoice Submission",
    [READYING_BACK_TRANSITION]: "Move to Ready for Invoice",
  };

  if (!modalKey || !TITLE_READABLE[modalKey]) {
    console.error(
      `Invalid modalKey passed to ModalHeadline, expected one of ${Object.keys(
        TITLE_READABLE
      ).join(", ")}, got ${modalKey}`
    );
    return null;
  }

  return (
    <ms.HeadlineRow>
      <ms.HeadlineTitleContainer>
        <ms.HeadlineIconContainer>
          {TITLE_ICONS[modalKey] || ""}
        </ms.HeadlineIconContainer>
        <ms.Headline>
          <h3 tabIndex={0} id="modalTitle">
            {TITLE_READABLE[modalKey] || ""}
          </h3>
          {subHeading && typeof subHeading === "string" && <p>{subHeading}</p>}
          {subHeading && typeof subHeading !== "string" && subHeading}
        </ms.Headline>
      </ms.HeadlineTitleContainer>
      <ms.ExitButton onClick={closeModal} aria-label="Close Modal">
        <ExitIcon />
      </ms.ExitButton>
    </ms.HeadlineRow>
  );
};

ModalHeadline.propTypes = {
  modalKey: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ModalHeadline;
