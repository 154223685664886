import React from "react";
import moment from "moment";
import axios from "axios";
import "react-dates/initialize";
import Swal from "sweetalert2";
import { components } from "react-select";
import styled from "styled-components";
import { SingleDatePicker } from "react-dates";
import SingleSelectWrapper from "../common/single_select_wrapper";
import NumberCounter from "../common/number_counter";
import RoundDropdown from "../projects/round_dropdown";
import CalendarIcon from "../icons/calendar_icon";
import ExitIcon from "components/icons/exit_icon";
import ClosureModalIcon from "components/icons/closure_modal_icon";

const roundDrop = (dropProps) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...dropProps}>
      <RoundDropdown />
    </components.DropdownIndicator>
  );
};

class AddTask extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      singleBooking: true,
      biWeekly: false,
      singleDate: moment(data.date),
      startDate: moment(data.date),
      endDate: moment(data.date),
      singlePickerFocus: false,
      startPickerFocus: false,
      endPickerFocus: false,
      hours: 8,
      project: [],
      days: [0, 0, 0, 0, 0],
      partTime: false,
      partTimeCheck: false,
      projectConfirmation: true,
      projectOptions: [],
      notes: "",
      taskGroup: null,
      editTaskGroup: false,
      loading: false,
      remote: true,
      lastWeekHours: null,
      currentWeekHours: null,
      originalData: {},
      projectValue: {
        label: data.project_id,
        value: data.project_id,
      },
      isTextareaFocused: false,
    };
  }

  componentDidMount() {
    const { data, setBackdropLoader, setDisableBackdropClick } = this.props;
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    if (data.taskId) {
      setBackdropLoader(true);
      setDisableBackdropClick(true);
      axios
        .get(`/edit_task_data/${data.taskId}?date=${data.date}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const projectOptions = res.data.projects.map((p) => {
            return {
              label: p.name,
              value: p.id,
              unavailable: p.sick_time || p.pto || p.unavailable,
            };
          });
          const project = projectOptions.find(
            (option) => res.data.task.project_id === option.value
          );
          this.setState(
            {
              singleBooking: !res.data.task.recurring_days,
              biWeekly: res.data.task.bi_weekly,
              days: res.data.task.recurring_days
                ? res.data.task.recurring_days
                : [0, 0, 0, 0, 0],
              hours: res.data.task.hours,
              startDate: moment(res.data.task.start_date),
              endDate: moment(res.data.task.end_date),
              originalData: res.data.task,
              partTime:
                !res.data.user.full_time && res.data.user.receive_confirmation,
              partTimeCheck:
                !res.data.user.full_time && !res.data.user.contractor,
              lastWeekHours: res.data.last_week_hours,
              currentWeekHours: res.data.current_week_hours,
              existingPTLimitRequest: res.data.existing_pt_limit_request,
              taskGroup: res.data.task_group,
              remote: res.data.task.remote,
              notes: res.data.task.notes,
              projectConfirmation: res.data.task.project_confirmation,
              projectOptions,
              project,
            },
            () => {
              setBackdropLoader(false);
            }
          );
        })
        .catch((err) => {
          setBackdropLoader(false);
          console.error(err);
        });
    } else {
      axios
        .get(`/add_task_data/${data.userId}?date=${data.date}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.setState({
            partTime:
              !res.data.user.full_time && res.data.user.receive_confirmation,
            partTimeCheck:
              !res.data.user.full_time && !res.data.user.contractor,
            remote: res.data.user.default_work_from_home,
            projectOptions: res.data.projects.map((p) => {
              return {
                label: p.name,
                value: p.id,
                unavailable: p.sick_time || p.pto || p.unavailable,
              };
            }),
            lastWeekHours: res.data.last_week_hours,
            currentWeekHours: res.data.current_week_hours,
            existingPTLimitRequest: res.data.existing_pt_limit_request,
          });
        })
        .catch((err) => console.error(err));
    }
  }

  handleSubmit = async () => {
    const { data, modalAction } = this.props;
    const {
      project,
      singleBooking,
      days,
      hours,
      biWeekly,
      partTimeCheck,
      projectConfirmation,
      originalData,
      currentWeekHours,
      lastWeekHours,
      startDate,
      endDate,
      existingPTLimitRequest,
      notes,
      partTime,
      remote,
      editTaskGroup,
      singleDate,
    } = this.state;
    this.setState({ loading: true });
    if (!project?.value) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: `Please select a project`,
        displayLength: 3000,
        classes: "red",
      });
      this.setState({ loading: false });
      return null;
    }
    if (!singleBooking && days.indexOf(1) === -1) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: `Select at least one recurring day of the week`,
        displayLength: 3000,
        classes: "red",
      });
      this.setState({ loading: false });
      return null;
    }

    let partTimeOverConfirmed = true;
    let cancel = false;
    let totalHours;

    if (
      partTimeCheck &&
      !project.unavailable &&
      projectConfirmation &&
      (!data.taskId || !originalData.project_confirmation)
    ) {
      totalHours = currentWeekHours + lastWeekHours;
      const daysBetween = moment(endDate).diff(moment(startDate), "days") - 1;
      if (
        hours === 8 &&
        ((days.indexOf(0) === -1 && !biWeekly) ||
          (daysBetween * hours > 72 && singleBooking))
      ) {
        await Swal.fire({
          title: `Part-Time Consecutive Hours Limit`,
          text: "You cannot book a Part-Time user for more than 72 consecutive hours.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Okay",
        });
        cancel = true;
      } else if (!singleBooking) {
        days.forEach((d) => {
          if (d === 1) {
            totalHours += hours;
          }
        });
      } else if (startDate !== endDate) {
        if (
          parseInt(moment(startDate).format("d"), 10) >
            parseInt(moment(endDate).format("d"), 10) ||
          daysBetween > 5
        ) {
          const startDay = parseInt(moment(startDate).format("d"), 10);
          for (let x = startDay; x <= 5; x += 1) {
            totalHours += hours;
          }
        } else {
          const loops =
            parseInt(moment(endDate).format("d"), 10) -
            parseInt(moment(startDate).format("d"), 10);
          for (let x = 0; x <= loops; x += 1) {
            totalHours += hours;
          }
        }
      } else {
        totalHours += hours;
      }
      if (totalHours > 72 && !cancel) {
        const existingRequest =
          existingPTLimitRequest && existingPTLimitRequest.length > 0;
        if (existingRequest && data.currentUserRoleId < 4) {
          await Swal.fire({
            title: `Existing Part-Time Limit Request`,
            text: "This user already has a pending request for extra hours this week. That request will need to be approved or closed before more hours can be requested.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Okay",
          });
          cancel = true;
        } else {
          await Swal.fire({
            title: `Part-Time Hours Limit`,
            text:
              data.currentUserRoleId > 3
                ? "This booking will exceed part-time employee hour limit. Would you like to proceed?"
                : `This booking will exceed part-time employee hour limit and needs to be approved by an Admin or HR. Would you like to proceed?`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.value) {
              if (data.currentUserRoleId < 4) {
                partTimeOverConfirmed = false;
              }
            } else {
              cancel = true;
            }
          });
        }
      }
    }
    if (cancel) {
      this.setState({ loading: false });
      return null;
    }
    if (!data.taskId) {
      axios
        .post(
          `/api/v1/tasks${totalHours ? `?total_hours=${totalHours}` : ""}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            task: {
              hours,
              start_date: moment(startDate).format("DD/MM/YYYY"),
              end_date: moment(endDate).format("DD/MM/YYYY"),
              notes,
              user_id: data.userId,
              project_confirmation: projectConfirmation,
              part_time_over_confirmed: partTimeOverConfirmed,
              send_notification:
                projectConfirmation &&
                partTime &&
                moment(endDate).isAfter(moment()),
              project_id: project.value,
              days,
              order: data.order ? data.order : 0,
              recurring: !singleBooking,
              bi_weekly: biWeekly,
              remote,
              confirmed: projectConfirmation,
            },
          }
        )
        .then(() => {
          // eslint-disable-next-line no-undef
          M.toast({
            html: `Booking${
              !singleBooking || startDate !== endDate ? "s" : ""
            } created`,
            displayLength: 3000,
            classes: "green",
          });
          modalAction(false, "", {});
        })
        .catch((err) => {
          // eslint-disable-next-line no-undef
          M.toast({
            html: `Server Error`,
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        });
    } else if (data.taskId && editTaskGroup) {
      axios
        .patch(
          `/api/v1/tasks/${data.taskId}${
            totalHours ? `?total_hours=${totalHours}` : ""
          }`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            task: {
              hours,
              start_date: startDate,
              end_date: endDate,
              created_by:
                data.userId !== data.currentUserId
                  ? data.currentUserId
                  : data.userId,
              notes,
              user_id: data.userId,
              part_time_over_confirmed: partTimeOverConfirmed,
              project_confirmation: projectConfirmation,
              send_notification:
                projectConfirmation &&
                partTime &&
                moment(endDate).isAfter(moment()),
              project_id: project.value,
              days,
              recurring: !singleBooking,
              bi_weekly: biWeekly,
              confirmed: projectConfirmation,
              remote,
              edit_task_group: editTaskGroup,
            },
          }
        )
        .then(() => {
          // eslint-disable-next-line no-undef
          M.toast({
            html: `Bookings updated`,
            displayLength: 3000,
            classes: "green",
          });
          modalAction(false, "", {});
        })
        .catch((err) => {
          // eslint-disable-next-line no-undef
          M.toast({
            html: `Server Error`,
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        });
    } else if (data.taskId) {
      axios
        .patch(
          `/api/v1/tasks/${data.taskId}${
            totalHours ? `?total_hours=${totalHours}` : ""
          }`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            task: {
              hours,
              start_date: singleDate,
              end_date: singleDate,
              notes,
              created_by:
                originalData.project_id !== project.value
                  ? data.currentUserId
                  : originalData.created_by,
              user_id: data.userId,
              project_confirmation: projectConfirmation,
              part_time_over_confirmed: partTimeOverConfirmed,
              bi_weekly: biWeekly,
              // linking user confirmed to project confirmed for now
              confirmed: projectConfirmation,
              send_notification:
                projectConfirmation &&
                partTime &&
                moment(singleDate).isAfter(moment()),
              project_id: project.value,
              edit_task_group: editTaskGroup,
              remote,
            },
          }
        )
        .then(() => {
          // eslint-disable-next-line no-undef
          M.toast({
            html: `Booking updated`,
            displayLength: 3000,
            classes: "green",
          });
          modalAction(false, "", {});
        })
        .catch((err) => {
          // eslint-disable-next-line no-undef
          M.toast({
            html: `Server Error`,
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        });
    }

    return null;
  };

  enableBackdropCheck = () => {
    const { data, disableBackdropClick, setDisableBackdropClick } = this.props;
    if (data.taskId && disableBackdropClick && !this.state.isTextareaFocused) {
      setDisableBackdropClick(false);
    }
  };

  handleInputChange = (e) => {
    this.setState({ project: e }, this.enableBackdropCheck);
  };

  handleProjectConfirmation = (e) => {
    this.setState(
      { projectConfirmation: e.target.value === "true" },
      this.enableBackdropCheck
    );
  };

  handleRemoteChange = (e) => {
    this.setState(
      { remote: e.target.value === "true" },
      this.enableBackdropCheck
    );
  };

  onSingleFocusChange = ({ focused }) => {
    this.setState({ singlePickerFocus: focused });
  };

  onStartFocusChange = ({ focused }) => {
    this.setState({ startPickerFocus: focused });
  };

  onEndFocusChange = ({ focused }) => {
    this.setState({ endPickerFocus: focused });
  };

  recurringBookingClick = (e) => {
    const { startDate, endDate } = this.state;
    const { value } = e.target;
    this.setState(
      {
        singleBooking: value === "true",
        biWeekly: value === "bi-weekly",
        startDate: !(value === "true") ? startDate.day("monday") : startDate,
        endDate: !(value === "true") ? endDate.day("monday") : endDate,
      },
      this.enableBackdropCheck
    );
  };

  editTaskGroupClick = (e) => {
    const { value } = e.target;
    const { singleBooking, taskGroup, startDate, endDate } = this.state;
    let updatedStart = startDate;
    let updatedEnd = endDate;
    if (value === "true") {
      updatedStart = singleBooking
        ? moment(taskGroup.start)
        : moment(taskGroup.start).day("monday");
      updatedEnd = singleBooking
        ? moment(taskGroup.end)
        : moment(taskGroup.end).day("monday");
    }
    this.setState(
      {
        editTaskGroup: value === "true",
        startDate: updatedStart,
        endDate: updatedEnd,
      },
      this.enableBackdropCheck
    );
  };

  // eslint-disable-next-line class-methods-use-this
  isDayBlocked = (day) => {
    return !!(
      moment(day).day() === 0 ||
      moment(day).day() === 6 ||
      moment(day) < moment().day("sunday")
    );
  };

  updateHours = (hours) => {
    this.setState({ hours }, this.enableBackdropCheck);
  };

  handleSingleDateChange = (date) => {
    this.setState({ singleDate: date }, this.enableBackdropCheck);
  };

  handleStartDateChange = (date) => {
    const { singleBooking, startDate, endDate } = this.state;
    this.setState(
      { startDate: singleBooking ? date : date.day("monday") },
      () => {
        if (startDate > endDate) {
          this.setState(
            { endDate: moment(startDate) },
            this.enableBackdropCheck
          );
        }
      }
    );
  };

  handleEndDateChange = (date) => {
    const { singleBooking, startDate, endDate } = this.state;
    this.setState(
      { endDate: singleBooking ? date : date.day("monday") },
      () => {
        if (endDate < startDate) {
          this.setState(
            { startDate: moment(endDate) },
            this.enableBackdropCheck
          );
        }
      }
    );
  };

  handleWeekdayClick = (e) => {
    const { days } = this.state;
    days[parseInt(e.target.dataset.index, 10)] =
      days[parseInt(e.target.dataset.index, 10)] === 1 ? 0 : 1;
    this.setState({ days }, this.enableBackdropCheck);
  };

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value ? e.target.value : "" }, () => {
      // Only enable the backdrop check if the textarea is not focused
      if (!this.state.isTextareaFocused) {
        this.enableBackdropCheck();
      }
    });
  };

  handleTextareaFocus = () => {
    this.setState({ isTextareaFocused: true });
  };

  handleTextareaBlur = () => {
    this.setState({ isTextareaFocused: false }, () => {
      this.enableBackdropCheck();
    });
  };
  closeModal = () => {
    const { modalAction } = this.props;
    modalAction(false, "", {});
  };

  deleteTask = () => {
    const { data, modalAction } = this.props;
    const { editTaskGroup } = this.state;

    Swal.fire({
      title: `Delete Booking${editTaskGroup ? "s" : ""}`,
      text: `Are you sure that you want to delete this booking${
        editTaskGroup ? " group" : ""
      }?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(
            `/api/v1/tasks/${data.taskId}?edit_task_group=${editTaskGroup}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            // eslint-disable-next-line no-undef
            M.toast({
              html: `Booking${editTaskGroup ? "s" : ""} deleted`,
              displayLength: 3000,
              classes: "green",
            });
            modalAction(false, "", {});
          })
          .catch((err) => {
            // eslint-disable-next-line no-undef
            M.toast({
              html: `Server Error`,
              displayLength: 3000,
              classes: "red",
            });
            console.error(err);
          });
      }
    });
  };

  render() {
    const { data } = this.props;
    const { taskId, createdById, createdByName, userId, userName, disabled } =
      data;

    const {
      editTaskGroup,
      startDate,
      endDate,
      singleBooking,
      lastWeekHours,
      loading,
      projectConfirmation,
      remote,
      projectOptions,
      project,
      projectValue,
      hours,
      taskGroup,
      biWeekly,
      days,
      singlePickerFocus,
      startPickerFocus,
      endPickerFocus,
      notes,
      singleDate,
    } = this.state;
    return (
      <div
        style={{ maxHeight: "88vh", overflow: "auto" }}
        className="modal-content "
      >
        <TaskModal
          taskId={taskId}
          editTaskGroup={editTaskGroup}
          startDate={startDate}
          endDate={endDate}
          recurring={!singleBooking}
        >
          <TitleContainer style={{ marginBottom: taskId ? "0px" : "32px" }}>
            <ClosureModalIcon />
            <TitleHeadline>
              {taskId ? "Edit" : "Create"} a booking
              {taskId && (
                <div
                  style={{
                    fontSize: "13px",
                    fontFamily: "arial",
                    fontWeight: "400",
                    marginTop: "4px",
                    fontWeight: "400",
                    marginBottom: "-4px",
                  }}
                >
                  {`Created By `}
                  <UserLink
                    target="_blank"
                    rel="noreferrer"
                    href={`/users/${createdById}`}
                  >
                    {`${createdByName} `}
                  </UserLink>
                  {`for `}

                  <UserLink
                    target="_blank"
                    rel="noreferrer"
                    href={`/users/${userId}`}
                  >
                    {userName}
                  </UserLink>
                </div>
              )}
              {lastWeekHours !== null && !taskId && (
                <LastWeekHours>
                  <LastWeekHoursText className="user-title">
                    {lastWeekHours} hours booked previous week
                  </LastWeekHoursText>
                </LastWeekHours>
              )}
            </TitleHeadline>
            <button
              type="button"
              id="modalClose"
              aria-label="Close Modal"
              className="btn-icon"
              onClick={this.closeModal}
              style={{ position: "absolute", top: "41px", right: "26px" }}
            >
              <ExitIcon
                svgStyles={{ pointerEvents: "none" }}
                height="25px"
                width="25px"
              />
            </button>
          </TitleContainer>
          <div>
            {taskId && (
              <DeleteBookingButton
                type="button"
                onClick={this.deleteTask}
                disabled={loading || disabled}
              >
                Delete Booking
              </DeleteBookingButton>
            )}
          </div>
          <div
            style={{
              padding: "0px 0px",
            }}
          >
            <FormRowTwoThirds>
              <FormItemHalf>
                <Label>Project Confirmed?</Label>
                <div style={{ display: "flex", marginTop: "16px" }}>
                  <label
                    htmlFor="project-confirmed-yes"
                    style={{ height: "16px" }}
                  >
                    <input
                      onChange={this.handleProjectConfirmation}
                      disabled={disabled}
                      className="with-gap"
                      type="radio"
                      name="project-confirmed"
                      id="project-confirmed-yes"
                      value
                      checked={projectConfirmation}
                      style={{ boxSizing: "border-box" }}
                    />
                    <span
                      className="schedule-radio"
                      style={{
                        marginRight: "15px",
                        fontFamily: "arial",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      Yes
                    </span>
                  </label>
                  <label htmlFor="project-confirmed-no">
                    <input
                      onChange={this.handleProjectConfirmation}
                      disabled={disabled}
                      className="with-gap"
                      type="radio"
                      name="project-confirmed"
                      id="project-confirmed-no"
                      value={false}
                      checked={!projectConfirmation}
                      style={{ boxSizing: "border-box" }}
                    />
                    <span
                      className="schedule-radio"
                      style={{
                        marginRight: "15px",
                        fontFamily: "arial",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      No
                    </span>
                  </label>
                </div>
              </FormItemHalf>
              <FormItemHalf>
                <Label>Work From Home?</Label>
                <div style={{ display: "flex", marginTop: "16px" }}>
                  <label for="wfh-yes">
                    <input
                      disabled={disabled}
                      onChange={this.handleRemoteChange}
                      className="with-gap"
                      type="radio"
                      name="wfr"
                      id="wfh-yes"
                      value
                      checked={remote}
                    />
                    <span
                      className="schedule-radio"
                      style={{
                        marginRight: "15px",
                        fontFamily: "arial",
                        fontSize: "13px",
                        fontWeight: "400",
                        boxSizing: "border-box",
                      }}
                    >
                      Yes
                    </span>
                  </label>
                  <label for="wfh-no">
                    <input
                      // disabled={disabled}
                      onChange={this.handleRemoteChange}
                      className="with-gap"
                      type="radio"
                      name="wfr"
                      id="wfh-no"
                      value={false}
                      checked={!remote}
                    />
                    <span
                      className="schedule-radio"
                      style={{
                        marginRight: "15px",
                        fontFamily: "arial",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      No
                    </span>
                  </label>
                </div>
              </FormItemHalf>
            </FormRowTwoThirds>
            <FormRowTwoThirds>
              <FormItem>
                <Label>
                  Project<span className="base-color">*</span>
                </Label>
                <SingleSelectWrapper
                  isSearchable
                  placeholder="Select a Project"
                  classNamePrefix="react-select"
                  options={projectOptions}
                  onChange={this.handleInputChange}
                  value={project}
                  defaultValue={projectValue}
                  blurInputOnSelect
                  components={{ DropdownIndicator: roundDrop }}
                  isDisabled={disabled}
                  customStyles={{
                    container: (styles) => ({
                      ...styles,
                      width: "300px",
                      overflow: "visible",
                    }),
                    singleValue: (styles) => ({
                      ...styles,
                      width: "100%",
                    }),
                    control: (styles) => ({
                      ...styles,
                      height: "39px",
                      width: "100%",
                    }),
                    theme: (theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#519acc",
                      },
                    }),
                  }}
                />
              </FormItem>
            </FormRowTwoThirds>
            <FormRow>
              <FormItem>
                <Label>Booking Hours</Label>
                <NumberCounter
                  disabled={disabled}
                  min={1}
                  max={8}
                  value={hours}
                  step={1}
                  updateValue={this.updateHours}
                />
              </FormItem>
            </FormRow>
            <div
              className="recurring-label-container"
              style={{
                display: "flex",
                marginTop: "0px",
                marginBottom: !singleBooking ? "16px" : "16px",
              }}
            >
              {taskId ? (
                taskGroup && (
                  <div style={{ marginRight: "82px" }}>
                    <Label>Update all bookings in group?</Label>
                    <label htmlFor="recurring-yes">
                      <input
                        disabled={disabled}
                        onChange={this.editTaskGroupClick}
                        className="with-gap"
                        type="radio"
                        name="recurring"
                        id="recurring-yes"
                        value
                        checked={editTaskGroup}
                      />
                      <span
                        style={{
                          marginRight: "15px",
                          fontFamily: "arial",
                          fontSize: "13px",
                          fontWeight: "400",
                        }}
                      >
                        Yes
                      </span>
                    </label>
                    <label htmlFor="recurring-no">
                      <input
                        disabled={disabled}
                        onChange={this.editTaskGroupClick}
                        className="with-gap"
                        type="radio"
                        name="recurring"
                        id="recurring-no"
                        value={false}
                        checked={!editTaskGroup}
                      />
                      <span
                        style={{
                          fontFamily: "arial",
                          fontSize: "13px",
                          fontWeight: "400",
                        }}
                      >
                        No
                      </span>
                    </label>
                  </div>
                )
              ) : (
                <div
                  className="recurring-label-container"
                  style={{ marginRight: "29px" }}
                >
                  <Label>Recurring?</Label>
                  <label htmlFor="recurring-bi-weekly">
                    <input
                      disabled={disabled}
                      onChange={this.recurringBookingClick}
                      className="with-gap"
                      type="radio"
                      name="recurring"
                      id="recurring-bi-weekly"
                      value="bi-weekly"
                      checked={!singleBooking && biWeekly}
                    />
                    <span
                      style={{
                        marginRight: "15px",
                        fontFamily: "arial",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      Bi-Weekly
                    </span>
                  </label>
                  <label htmlFor="recurring-yes">
                    <input
                      disabled={disabled}
                      onChange={this.recurringBookingClick}
                      className="with-gap"
                      type="radio"
                      name="recurring"
                      id="recurring-yes"
                      value="weekly"
                      checked={!singleBooking && !biWeekly}
                    />
                    <span
                      style={{
                        marginRight: "15px",
                        fontFamily: "arial",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      Weekly
                    </span>
                  </label>
                  <label htmlFor="recurring-no">
                    <input
                      disabled={disabled}
                      onChange={this.recurringBookingClick}
                      className="with-gap"
                      type="radio"
                      name="recurring"
                      id="recurring-no"
                      value
                      checked={singleBooking}
                    />
                    <span
                      style={{
                        fontFamily: "arial",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      No
                    </span>
                  </label>
                </div>
              )}
              {!singleBooking && (!taskId || editTaskGroup) && (
                <div id="days-container">
                  <span className="modal-field">Recurring Days</span>
                  <div style={{ marginTop: "8px" }}>
                    <button
                      type="button"
                      onClick={this.handleWeekdayClick}
                      data-index={0}
                      style={{
                        backgroundColor: days[0] === 1 ? "#519acc" : "#ececec",
                        color: days[0] === 1 ? "white" : "#313131",
                      }}
                    >
                      M
                    </button>
                    <button
                      type="button"
                      onClick={this.handleWeekdayClick}
                      data-index={1}
                      style={{
                        backgroundColor: days[1] === 1 ? "#519acc" : "#ececec",
                        color: days[1] === 1 ? "white" : "#313131",
                      }}
                    >
                      T
                    </button>
                    <button
                      type="button"
                      onClick={this.handleWeekdayClick}
                      data-index={2}
                      style={{
                        backgroundColor: days[2] === 1 ? "#519acc" : "#ececec",
                        color: days[2] === 1 ? "white" : "#313131",
                      }}
                    >
                      W
                    </button>
                    <button
                      type="button"
                      onClick={this.handleWeekdayClick}
                      data-index={3}
                      style={{
                        backgroundColor: days[3] === 1 ? "#519acc" : "#ececec",
                        color: days[3] === 1 ? "white" : "#313131",
                      }}
                    >
                      Th
                    </button>
                    <button
                      type="button"
                      onClick={this.handleWeekdayClick}
                      data-index={4}
                      style={{
                        backgroundColor: days[4] === 1 ? "#519acc" : "#ececec",
                        color: days[4] === 1 ? "white" : "#313131",
                      }}
                    >
                      F
                    </button>
                  </div>
                </div>
              )}
            </div>
            {editTaskGroup && (
              <div
                className="recurring-label-container"
                style={{ marginRight: "29px", position: "relative" }}
              >
                <Label>Recurring Term</Label>
                <label htmlFor="recurring-bi-weekly-edit">
                  <input
                    disabled={disabled}
                    onChange={this.recurringBookingClick}
                    className="with-gap"
                    type="radio"
                    name="recurring-edit"
                    id="recurring-bi-weekly-edit"
                    value="bi-weekly"
                    checked={!singleBooking && biWeekly}
                  />
                  <span
                    className="schedule-radio"
                    style={{
                      marginRight: "15px",
                      fontFamily: "arial",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Bi-Weekly
                  </span>
                </label>
                <label htmlFor="recurring-yes-edit">
                  <input
                    disabled={disabled}
                    onChange={this.recurringBookingClick}
                    className="with-gap"
                    type="radio"
                    name="recurring-edit"
                    id="recurring-yes-edit"
                    value="weekly"
                    checked={!singleBooking && !biWeekly}
                  />
                  <span
                    style={{
                      fontFamily: "arial",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Weekly
                  </span>
                </label>
              </div>
            )}
            {taskId && !editTaskGroup ? (
              <div
                style={{ height: "65px" }}
                className="flex-j-start flex-container flex-al-center"
              >
                <div
                  id="start-date-container"
                  style={{ marginLeft: "-22px" }}
                  className="flex-j-start flex-container flex-al-center"
                >
                  <div
                    style={{
                      position: "relative",
                      left: "40px",
                      zIndex: 1,
                      top: "5px",
                    }}
                  >
                    <CalendarIcon width="22px" height="22px" color="#519acc" />
                  </div>
                  <SingleDatePicker
                    date={singleDate}
                    onDateChange={this.handleSingleDateChange}
                    focused={singlePickerFocus}
                    onFocusChange={this.onSingleFocusChange}
                    numberOfMonths={1}
                    disabled={disabled}
                    placeholder="Select a date"
                    orientation="horizontal"
                    daySize={30}
                    navPrev={
                      <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(90deg)" />
                      </div>
                    }
                    navNext={
                      <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(-90deg)" />
                      </div>
                    }
                    hideKeyboardShortcutsPanel
                    inputIconPosition="after"
                    customInputIcon={<RoundDropdown />}
                    isOutsideRange={this.isDayBlocked}
                    id="single_date_picker"
                  />
                  <label
                    style={{ paddingLeft: "4px" }}
                    className="date-labels"
                    htmlFor="filter_filters"
                  >
                    Booking Date<span className="base-color">*</span>
                  </label>
                </div>
              </div>
            ) : (
              <div
                style={{ height: "65px", marginTop: "24px" }}
                className="flex-j-start flex-container flex-al-center"
              >
                <div
                  id="start-date-container"
                  style={{ marginLeft: "-22px", maxWidth: "184px" }}
                  className="flex-j-start flex-container flex-al-center"
                >
                  <div
                    style={{
                      position: "relative",
                      left: "40px",
                      zIndex: 1,
                      top: "5px",
                    }}
                  >
                    <CalendarIcon width="22px" height="22px" color="#519acc" />
                  </div>
                  <SingleDatePicker
                    date={startDate}
                    onDateChange={this.handleStartDateChange}
                    focused={startPickerFocus}
                    onFocusChange={this.onStartFocusChange}
                    numberOfMonths={1}
                    placeholder="Select a date"
                    orientation="horizontal"
                    disabled={disabled}
                    daySize={30}
                    navPrev={
                      <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(90deg)" />
                      </div>
                    }
                    navNext={
                      <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(-90deg)" />
                      </div>
                    }
                    hideKeyboardShortcutsPanel
                    inputIconPosition="after"
                    customInputIcon={<RoundDropdown />}
                    isOutsideRange={this.isDayBlocked}
                    id="single_date_picker" // PropTypes.string.isRequired,
                  />
                  <label
                    style={{ paddingLeft: "4px" }}
                    className="date-labels"
                    htmlFor="filter_filters"
                  >
                    Start {singleBooking ? "Date" : "Week"}
                    <span className="base-color">*</span>
                  </label>
                </div>
                <div
                  id="end-date-container"
                  style={{ marginLeft: "18px" }}
                  className="flex-j-start flex-container flex-al-center"
                >
                  <div
                    style={{
                      position: "relative",
                      left: "40px",
                      zIndex: 1,
                      top: "5px",
                    }}
                  >
                    <CalendarIcon width="22px" height="22px" color="#519acc" />
                  </div>
                  <SingleDatePicker
                    date={endDate}
                    onDateChange={this.handleEndDateChange}
                    focused={endPickerFocus}
                    onFocusChange={this.onEndFocusChange}
                    numberOfMonths={1}
                    placeholder="Select a date"
                    orientation="horizontal"
                    daySize={30}
                    disabled={disabled}
                    navPrev={
                      <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(90deg)" />
                      </div>
                    }
                    navNext={
                      <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(-90deg)" />
                      </div>
                    }
                    hideKeyboardShortcutsPanel
                    inputIconPosition="after"
                    customInputIcon={<RoundDropdown />}
                    isOutsideRange={this.isDayBlocked}
                    id="single_date_picker" // PropTypes.string.isRequired,
                  />
                </div>
                <label
                  style={{ paddingLeft: "4px" }}
                  className="date-labels"
                  htmlFor="filter_filters"
                >
                  End {singleBooking ? "Date" : "Week"}
                  <span className="base-color">*</span>
                </label>
              </div>
            )}
            <FormRow>
              <FormItem>
                <Label>Notes</Label>
                <textarea
                  disabled={disabled}
                  style={{
                    minHeight: "110px",
                    padding: "5px 8px",
                    marginTop: "0px",
                  }}
                  name="notes"
                  id="task_notes"
                  className="browser-default text-input-v2"
                  onChange={this.handleNotesChange}
                  onFocus={this.handleTextareaFocus}
                  onBlur={this.handleTextareaBlur}
                  value={notes}
                  maxLength="240"
                  aria-describedby="character-count"
                />

                <p
                  id="character-count"
                  aria-live="polite"
                  className="input-counter"
                >{`${notes.length}/240`}</p>
              </FormItem>
            </FormRow>
            <div style={{ marginTop: "-20px" }} className="right-align">
              <button
                type="button"
                name="cancel"
                value="Cancel"
                onClick={this.closeModal}
                className="common-button-cancel"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </button>
              <button
                disabled={loading || disabled}
                type="button"
                name="create"
                value="Create"
                onClick={this.handleSubmit}
                className="common-button-submit"
              >
                {taskId ? "Update" : "Create"} Booking
              </button>
            </div>
          </div>
        </TaskModal>
      </div>
    );
  }
}

const TitleHeadline = styled.h2`
  font-size: 18px;
  margin: 0px 0px 0px 16px;
  font-weight: 600;
  font-family: Manrope;
`;

const TitleContainer = styled.div`
  padding: 0px 0px;
`;

const UserLink = styled.a`
  color: #519acc;
  font-size: 13px;
  font-family: Arial;
  font-weight: 400;
`;

const LastWeekHoursText = styled.span`
  color: #a7a7a7;
  font-style: italic;
  font-size: 14px;
  font-family: Arial;
`;

const LastWeekHours = styled.div`
  font-size: 14px;
  margin-top: 8px;
  font-weight: 400;
  margin-bottom: -4px;
`;

const DeleteBookingButton = styled.button`
  display: block;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  color: #519acc;
  padding-left: 0px;
  margin: 16px 0px 32px;
`;

const TaskModal = styled.div.attrs((props) => ({
  recurring: props.recurring,
  startDate: props.startDate,
  endDate: props.endDate,
  editTaskGroup: props.editTaskGroup,
  taskId: props.taskId,
}))`
  > div:nth-child(1) {
    display: flex;
    align-items: center;
  }

  > div:nth-child(1) img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .react-select__input > input {
    margin-left: 2px;
    text-shadow: 0px 0px #313131;
    padding-left: 5px;
  }

  .react-select__placeholder,
  .react-select__single-value {
    padding-top: 2px;
    font-size: 14px;
    padding-left: 5px;
    overflow: visible;
    font-family: Arial;
    max-width: 300px !important;
  }

  .react-select__menu {
    width: calc(100% - 8px);
    transform: translateX(2px);
  }

  .react-select__indicators {
    height: 39px;
    position: absolute;
    right: 0px;
    background-color: #fff;
  }

  .react-select__control {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &:hover {
      border-color: #519acc;
    }
  }

  textarea {
    margin-bottom: 4px;
  }

  #days-container button {
    border: solid 1px lightgray;
    transition: background-color 0.2s, color 0.2s;
    cursor: pointer;
  }

  .hide-focus:focus {
    box-shadow: none;
    outline: none;
  }

  #start-date-container .SingleDatePicker_picker {
    left: 1px !important;
    top: 55px !important;
  }

  #end-date-container .SingleDatePicker_picker {
    left: -41px !important;
    top: 55px !important;
  }

  ${(props) =>
    (!props.taskId && props.recurring) ||
    (props.taskId && props.recurring && props.editTaskGroup)
      ? `
    .CalendarMonth_table tr:hover .CalendarDay__default:not(.CalendarDay__blocked_out_of_range){
      background-color: #519acc;
    }

    .CalendarDay__default:hover{
      background-color: #519acc !important;
    }

    .CalendarDay__selected ~ td:not(.CalendarDay__blocked_out_of_range) {
      background: #519acc !important;
      color: #fff !important;
    }

    .CalendarDay__selected ~ td:not(.CalendarDay) {
      border-radius: 50%;
      opacity: 0.6;
    }

    .CalendarDay__selected ~ td:not(.CalendarDay):last-child {
      opacity: 0;
    }

    #start-date-container .DateInput::after{
      content: "${moment(props.startDate)
        .day("monday")
        .format("MM/DD")} - ${moment(props.startDate)
          .day("friday")
          .format("MM/DD")}";
      display: block;
      position: absolute;
      width: 100px;
      height: 22px;
      color: #484848;
      font-weight: 200;
      font-size: 14px;
      background-color: white;
      top: 2px;
      font-family: Arial;
    }

    #end-date-container .DateInput::after{
      content: "${moment(props.endDate)
        .day("monday")
        .format("MM/DD")} - ${moment(props.endDate)
          .day("friday")
          .format("MM/DD")}";
      display: block;
      position: absolute;
      width: 100px;
      height: 22px;
      color: #484848;
      font-weight: 200;
      font-size: 14px;
      background-color: white;
      top: 2px;
      font-family: Arial;
    }

  `
      : ""}
`;

const Label = styled.p`
  margin-bottom: 8px;
  margin-top: 0;
  font-family: Manrope;
  font-size: 13px;
  font-weight: 600;
`;

const FormRow = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
`;

const FormRowTwoThirds = styled(FormRow)`
  gap: 32px;
`;

const FormItem = styled.div`
  flex-basis: 100%;
  max-width: 100%;
`;

const FormItemHalf = styled(FormItem)`
  max-width: 121px;
`;

export default AddTask;
